import {
  Button,
  Grid,
  makeStyles,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  FieldArray,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import { useContext, useEffect, useState } from "react";
import FormTextInput from "../../../../../component/Form/FormTextInput";

import * as Yup from "yup";
import {
  apiRequest,
  API_ENDPOINT_PREFIX,
  convertToFormData,
} from "../../../../../helper/fetching";
import { PageContext } from "../../../../../context/PageContext";
import FormSection from "../../../../../component/Form/FormSection";
import BasicInfoSection from "../FormSection/BasicInfoSection";
import StoneSection from "../FormSection/StoneSection";
import RemarkSection from "../FormSection/RemarkSection";
import GoldSection from "../FormSection/GoldSection";
import FactorySection from "../FormSection/FactorySection";
import { validteField } from "../../../../../helper/validate";
import { createFormSchema } from "../../Schema/CreateFormSchema";
import OrderDetailSection from "../FormSection/OrderDetailSection";
import SearchSection from "../FormSection/SearchSection";
import DiamondSection from "../FormSection/DiamondSection";
import GemSection from "../FormSection/GemSection";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  informationWrapper: {
    marginBottom: 20,
  },
  formTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
}));

const CreateForm = ({
  closeForm,
  callInfo = { by: "", id: "", quotation_ids: [], setValueForTable: () => {} },
  ...rest
}) => {
  const classes = useStyles();
  const { fetchProducts, fetchPurchaseOrder } = useContext(PageContext);

  //work order use to submit to api
  // const [workOrder, setWorkOrder] = useState("");

  const [preSetValues, setPreSetValues] = useState("");

  //selectedOption is for storing search result
  const [selectedOption, setSelectedOption] = useState("");

  const [quotation_id, setQuotation_id] = useState(null);

  const getfourWeeksLater = () => {
    const today = new Date(moment().format("YYYY-MM-DD"));
    const fourWeeksLater = today.getTime() + 4 * 7 * 24 * 60 * 60 * 1000;
    const date = new Date(fourWeeksLater);
    while (date.getDay() !== 2) {
      date.setDate(date.getDate() + 1);
    }
    return moment(date).format("YYYY-MM-DD");
  };

  const formik = useFormik({
    initialValues: {
      id_no: null,
      client_nickname: null,
      customer_item_no: null,
      full_name: "",
      identifier: "",
      category: "",
      wrist_size: "",
      chain_length: "",
      zhen_fen_se: "",
      dian_fen_se: "",
      lu_zhu_bian: "",
      er_zhen: "",
      xian_pai: "",
      shi_mian_dian_bai: "",
      shi_di_dian_bai: "",
      dian_huang: "",
      dian_hung: "",
      dian_hei: "",
      work_order_er_po: "",
      po_5_5: "",
      po_4_5: "",
      pi_po: "",
      b_b_po: "",
      zhi_mo: "",
      ding_ban: "",
      zhao_xiang: "",
      qi_ding: "",
      ao_zhou_xiang: "",
      po_xiang: "",
      other: "",
      la_zhong: "",
      zhi_mo_zhong: "",
      zhi_bian_zhong: "",
      tu_zhi: "",
      mo: "",
      la: "",
      qi_ya: "",
      hong_kong_remark: "",
      molding_remark: "",
      zhi_mo_remark: "",
      xiang_shi_remark: "",
      gold_type_id: "",
      gold_type: "",
      gold_weight: "",
      gold_style: "",
      stones: [],
      quotation_gold_weight: "",
      quotation_net: "",
      // factory_po_no: "",
      gold_limit_weight: "",

      //basic field
      description: "",
      aa_no: "",
      style_no: "",
      diamond_practical_qty: "",
      diamond_practical_weight: "",
      gem_practical_qty: "",
      gem_practical_weight: "",
      chain_weight: "",
      hook_weight: "",
      incoming_gold_weight: "",
      accessories_weight: "",
      xia_bi: "",
      er_po: "",
      order_date: moment().format("YYYY-MM-DD"),
      shipping_date: getfourWeeksLater(),

      deduct_diamonds: [],
      deduct_gems: [],
      deduct_stones: [],
      factory_field: {
        factory_shipping_date: null,
        factory_stone_weight: null,
        factory_lin_stone_weight: null,
        factory_gold_weight: null,
        factory_gold_limit_weight: null,
        factory_gold_limit_weight_product: null,
        factory_lin_stone_weight_price: null,
        factory_lin_stone_weight_order: null,
        car_stone_fee_price: null,
        car_stone_fee_order: null,
        accessories_fee_price: null,
        accessories_fee_order: null,
        mold_fee_price: null,
        mold_fee_order: null,
        factory_ji_gold_price: null,
        factory_ji_gold_order: null,
        factory_salary_price_1: null,
        factory_salary_price_2: null,
        factory_salary_price_3: null,
        factory_salary_price_4: null,
        factory_salary_price_5: null,
        factory_salary_price_remarks_1: null,
        factory_salary_price_remarks_2: null,
        factory_salary_price_remarks_3: null,
        factory_salary_price_remarks_4: null,
        factory_salary_price_remarks_5: null,
        factory_salary_order_1: null,
        factory_salary_order_2: null,
        factory_salary_order_3: null,
        factory_salary_order_4: null,
        factory_salary_order_5: null,
        factory_salary_order_remarks_1: null,
        factory_salary_order_remarks_2: null,
        factory_salary_order_remarks_3: null,
        factory_salary_order_remarks_4: null,
        factory_salary_order_remarks_5: null,
        factory_gwa_or_den_or_mai_price: null,
        factory_gwa_or_den_or_mai_order: null,
        factory_ga_lin_price: null,
        factory_ga_lin_order: null,
        factory_ban_fei_price: null,
        factory_ban_fei_order: null,
        factory_stone_price: null,
        factory_total_count: null,
        factory_old_wg: null,
        factory_t_or_p: null,
        factory_net: null,
        factory_old_no: null,
        factory_po_no: null,
      },
      gems: [],
      diamonds: [],
    },
    enableReinitialize: true,

    validationSchema: Yup.object().shape(createFormSchema),

    onSubmit: async (data, { setSubmitting, resetForm }) => {
      const formattedData = {
        ...data,
        // client: data.client,
        client_id: data.client.id,
        image_id: data.images ? data.images.id : "",
        factory_id: data.factory.id,
        factory_field: data.factory_fields || data.factory_field,
        // deduct_diamonds: [],
        // deduct_gems: [],
        // deduct_stones: [],
        quotation_id: quotation_id,
      };

      try {
        let url =
          callInfo.by === "Purchase-Order"
            ? `${API_ENDPOINT_PREFIX}/purchase-order/${callInfo.id}/work-order`
            : `${API_ENDPOINT_PREFIX}/work-order`;

        const res = await apiRequest().post(url, formattedData);

        const json = res.data;
        closeForm();
        resetForm();
        window.scrollTo(0, 0);
        callInfo.setValueForTable(json.data);
        fetchPurchaseOrder && (await fetchPurchaseOrder());
        fetchProducts && (await fetchProducts());
      } catch (err) {
      } finally {
        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    const { id, id_no, ...cloneData } = rest.entity;

    let uniqueNumber = 1;
    let newId_no =
      id_no +
      `${uniqueNumber < 10 ? "0" + uniqueNumber : uniqueNumber.toString()}`;

    formik.setValues({
      ...cloneData,
      // id_no: cloneData.id_no + Math.floor(100000 + Math.random() * 900000)
      id_no: newId_no,
    });
  }, []);

  return (
    <FormikProvider value={formik}>
      <div {...rest}>
        <div>
          <Typography variant="h3" className={classes.formTitle}>
            複製製工單
          </Typography>

          {/* <Grid item xs={6} md={2} lg={2}>
            <FormTextInput
              variant="outlined"
              id="factory_po_no"
              label="PO Number"
              name="factory_po_no"
              value={formik.values.factory_po_no}
              onChange={formik.handleChange}
              required={false}
              InputLabelProps={{ shrink: true }}
            />
          </Grid> */}

          {/* <SearchSection
            // workOrder={workOrder}
            // setWorkOrder={setWorkOrder}
            preSetValues={preSetValues}
            setPreSetValues={setPreSetValues}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          /> */}

          {callInfo.by === "Purchase-Order" ? (
            <TextField
              select
              style={{ width: 200 }}
              variant="outlined"
              size="small"
              onChange={(e) => setQuotation_id(e.target.value)}
            >
              {callInfo.quotation_ids?.map((item) => (
                <MenuItem value={item.id}>
                  ID: {item.id} --- Identifier: {item.identifier}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          <FormSection
            title="製工單基本信息"
            renderContent={() => <OrderDetailSection mode="add" />}
            collaspe
          />
          <FormSection
            title="產品基本信息"
            renderContent={() => <BasicInfoSection />}
            collaspe
          />

          <FormSection
            title="金資料"
            renderContent={() => <GoldSection />}
            collaspe
          />

          <FormSection
            title="鑽石資料"
            renderContent={() => <DiamondSection />}
            collaspe
          />

          <FormSection
            title="寶石資料"
            renderContent={() => <GemSection />}
            collaspe
          />

          <FormSection
            title="石資料"
            renderContent={() => <StoneSection />}
            collaspe
          />

          <FormSection
            title="備註部分"
            renderContent={() => <RemarkSection />}
            collaspe
          />

          <FormSection
            title="廠方部分"
            renderContent={() => <FactorySection />}
            collaspe
            defaultExpand={false}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            提交
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
};

export default CreateForm;
